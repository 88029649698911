/*::-webkit-scrollbar {
  
}*/

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  transition: .5s;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* Handle on hover */
/*::-webkit-scrollbar-thumb:hover {
  background: #555;
}*/